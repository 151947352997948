<template>
  <v-dialog
    v-model="show"
    width="75vw"
    class="dialog-1"
    scrollable
  >
    <v-card v-if="workload">
      <v-card-title class="text-h5 accent white--text">
        <p>
          Details for {{ workload.type}}
        </p>
        <p class="font-weight-thin mx-1">
          {{ getName(workload) }}
        </p>
        <p>
          on {{ environment.name }}
        </p>
      </v-card-title>
      <v-progress-linear color="accent" :indeterminate="loading" class="mb-6" />


      <v-card-text v-if="workloadInfo">
        <div
          v-for="(pod, index) in workloadInfo.items"
          :key="index"
        >
          <v-row class="justify-space-between align-center pb-2">
            <v-col cols=4>
              Start: {{ getDate(pod) }}
            </v-col>
            <v-col cols=4>
              <span class="monospace">
                Name: {{ getName(pod.metrics) }}
              </span>
            </v-col>
            <v-col cols=2>
              Node: <span clas="monospace">{{ pod.spec.nodeName }}</span>
            </v-col>
            <v-col cols=2 align=right>
              <v-chip dark color=primary>
                {{ pod.status.phase }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row
            v-for="(container, idx) in getContainerMetrics(pod.metrics)"
            :key="idx"
            class="secondary"
            :class="idx % 2 ? 'font-weight-medium' : 'font-weight-regular'"
            no-gutters
          >
            <v-col
              cols="7"
              class="flex-shrink-1 flex-grow-0 px-3"
            >
              Container:
              <span class="monospace">{{ container.name }}</span>
            </v-col>
            <v-col cols="2">
              CPU: {{ container.usage.cpu }}
            </v-col>
            <v-col cols="2">
              Memory: {{ container.usage.memory }}
            </v-col>
            <v-col cols="1">
              <v-btn
                @click="$emit('showDialog', pod.spec.containers[idx])"
                color="primary"
                icon
              >
                <v-icon v-text="'$info'" />
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          {{ labels.close }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { get } from 'lodash';
import { labels } from '@/assets/texts.json';
import { formatDate, getName, getNamespace } from '@/helpers';

export default {
  name: 'podDetails',
  props: {
    workload: {
      type: Object,
      required: true,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState('fetch', [
      'environment',
      'workloadInfo',
    ]),
    ...mapState('shared', [
      'loading',
    ]),
  },
  data: () => ({
    labels,
  }),
  methods: {
    ...mapActions({
      fetchPodInfo: 'fetch/fetchPodInfo',
    }),
    getContainerMetrics(obj) {
      return get(obj, 'containers');
    },
    getData() {
      const params = {
        name: getName(this.workload),
        namespace: getNamespace(this.workload),
        workload: this.workload.type,
      };
      this.fetchPodInfo({
        environmentID: this.environment.id,
        params,
      });
    },
    getDate(pod) {
      return formatDate(get(pod, 'status.startTime'));
    },
    getName,
  },
};
</script>
